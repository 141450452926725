const config = {
  apiGateway: {
    REGION: 'us-east-2',
    INTERNAL_API_URL: 'https://aof4pr6ke3.execute-api.us-east-2.amazonaws.com/prod',
    TARGET_API_URL: 'https://ox4sxmknud.execute-api.us-east-2.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.testing.vale.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: 'https://id.testing.vale.forwoodsafety.com/quicksight-login?qs_redirect_uri=https://us-east-2.quicksight.aws.amazon.com/sn/dashboards/3ebac216-f663-48df-b7f8-311d2462e930?session_timeout_redirect_url=id.testing.vale.forwoodsafety.com/quicksight-login',
    CLIENT_TIMEZONE: 'America/Sao_Paulo'
  },
  reactApp: {
    VERSION: 'targets-1.7.0',
    HOSTNAME: 'https://targets.testing.vale.forwoodsafety.com'
  }
};

export default config;